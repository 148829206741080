import { BREADCRUMBS_EVENT_DETAILS } from '@/constants/BreadCrumbsRoute'
import { EventHiddenCategories } from '@/constants/FieldsEvent'
import {
  FRONT_EVENT_DETAILS,
  ROUTE_ECOSYSTEM_EVENTS,
  ROUTE_LOGIN,
  ROUTE_NOT_FOUND
} from '@/constants/Routes'
import type { Event } from '@/models/calendar/Event'
import userStore from '@/stores/userStore'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { isAxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useSWR from 'swr'
import EditDuplicateButtons from '../portal/EditDuplicateButtons'
import BreadCrumbs from '../shared/BreadCrumbs'
import InjectHtml from '../shared/InjectHtml'
import DetailsFetchingHandling from '../shared/dataFetching/DetailsFetchingHandling'
import DisplayContents from '../shared/details/DisplayContents'
import DisplayImageAndCredit from '../shared/details/DisplayImageAndCredit'
import DisplayTitle from '../shared/details/DisplayTitle'
import EventDetailsAgenda from './components/EventDetailsAgenda'
import EventDetailsProgramInfo from './components/EventDetailsProgramInfo'

const EventDetails = () => {
  const { query } = useRouter()
  const router = useRouter()
  const isConnected = userStore(s => !!s.user)

  const [isKnovaMember] = userStore(e => [e.isKnovaMember])

  const { data, error } = useSWR<Event>(
    query.id ? FRONT_EVENT_DETAILS(query.id.toString()) : null,
    baseFetcher,
    { revalidateOnFocus: false }
  )
  useEffect(() => {
    if (isAxiosError(error) && error.response?.status === 403) {
      router.replace(ROUTE_NOT_FOUND)
    }
  }, [error])

  useEffect(() => {
    if (!isKnovaMember && data?.categoryHidden === EventHiddenCategories.membersOnly) {
      // noinspection JSIgnoredPromiseFromCall
      router.replace(ROUTE_NOT_FOUND)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!isConnected) router.replace(ROUTE_LOGIN)

  return (
    <div className='section'>
      <DetailsFetchingHandling data={data} error={error} />
      {data && query.id && (
        <>
          <BreadCrumbs
            text={BREADCRUMBS_EVENT_DETAILS(data.title)}
            backLink={ROUTE_ECOSYSTEM_EVENTS}
          />
          <div className='wrapper'>
            <EditDuplicateButtons id={query.id.toString()} type='event' />
            <div className='grid-detail margin-bottom-8x'>
              <div className='block-content'>
                <DisplayTitle title={data.title} subtitle={data.subtitle} />
                <DisplayImageAndCredit image={data.image} imageCredit={data.imageCredit} />

                <div className='wrapper-detail-content'>
                  <DisplayContents firstParagraph={data.firstParagraph} content={data.content} />
                  <div className='margin-bottom-4x' />
                  <EventDetailsAgenda eventId={parseInt(query.id.toString())} />
                  {data.motivationItems && (
                    <div className='text w-richtext'>
                      <h5>Why attend &amp; Target Audience:</h5>
                      <InjectHtml data={data.motivationItems} wrapInRichText={false} />
                    </div>
                  )}
                </div>
              </div>
              <EventDetailsProgramInfo event={data} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default EventDetails
